import React from 'react'

export default function About() {
    return (
        <div className='about flex-col text-center'>
            <h1 className='main-heading text-4xl my-10'>PHARMACEUTICALS</h1>
            <div className='flex max-md:flex-col max-md:p-2 p-10 justify-around h-2/5'>
                <p className='text-xl sm:text-3xl rounded bg-slate-100  text-left p-10 m-3 md:m-5 shadow-xl sm:p-6 lg:p-8'>We are a team with experience in creating drugs that show effects within a few weeks. Over the years, Russian Biotech has grown from a cottage industry to one of the leading pharmaceutical companies in the world. The reason for our success is our efficient team working tirelessly to improve the product and deliver it to those in need. We supply our products to patients, doctors, pharmacies and collaborators in different countries. The affordable prices of the products offered by our company enable us to serve the masses and help the world lead a healthy and happy life.</p>
                <img className=' w-3/4 md:w-1/2 ml-10' src="/images/about-1.webp" alt="" />
            </div>
            <h1 className='main-heading text-4xl my-10'>Our mission is a healthy community</h1>
            <div className='md:flex p-5 md:p-10 justify-around h-2/5'>
               <img className=' w-3/4 md:w-1/2 ml-10' src="/images/about-2.webp" alt="" />
               <p className=' text-xl sm:text-3xl rounded bg-slate-100  text-left p-10 mt-5 md:m-5 shadow-xl sm:p-6 lg:p-8'>Our mission is to make people's lives better by improving their health with our products and to make our company a household name. We have a wide range of products to address a variety of hormonal concerns. Our goal is to provide top quality products at reasonable prices. Our goal is to satisfy our customers who suffer from various disorders with our quality products and door to door service. Russian Biotech has dedicated production, packaging, distribution and export teams.</p>
            </div>
            <h1 className='main-heading text-4xl my-10'>our team</h1>

            <div className="md:flex px-5 ">

            <div class="rounded bg-slate-100  p-4 m-5 shadow-xl sm:p-6 lg:p-8">
            <h3 class="text-2xl font-bold sm:text-2xl">PRODUCTION UNIT</h3>
          <p class="mt-4 font-medium text-gray-500">
            Production unit, This division manufactures medicines certified by the World Health Organization. The quality and affordable prices of products are appreciated by our customers. We have a dedicated team of scientists who work tirelessly to ensure that our products are superior in quality and affordable.
          </p>
          </div>

          <div class="rounded bg-slate-100  p-4 m-5 shadow-xl sm:p-6 lg:p-8">
         <h3 class="text-2xl font-bold sm:text-2xl">PACKING UNIT</h3>
          <p class="mt-4 font-medium text-gray-500">
          Medicines are perfectly packed by our packing machine. Information, dosage and ingredients of the product are indicated on the packaging. The entire process in our packaging unit is environmentally friendly, while making the most of sustainable products.
          </p>
           </div>


           <div class="rounded bg-slate-100  p-4 m-5 shadow-xl sm:p-6 lg:p-8">
              <h3 class="text-2xl font-bold sm:text-2xl">DISTRIBUTION BLOCK</h3>
               <p class="mt-4 font-medium text-gray-500">
                Our shipping costs are one of the lowest. We have door to door delivery which makes us convenient for customers. Delivery is carried out strictly within the time specified when placing the order. We also keep our customers informed about the status of their package.
              </p>
           </div>

           <div class="rounded bg-slate-100  p-4 m-5 shadow-xl sm:p-6 lg:p-8">
              <h3 class="text-2xl font-bold sm:text-2xl">EXPORT UNIT</h3>
               <p class="mt-4 font-medium text-gray-500">
               The huge network of our company is managed by the export department. It not only exports medicines in good condition to various parts of the world, but also promotes the company through services such as inquiries, customer escorts, and participation in trade missions.
              </p>
           </div>

            </div>

            <button className='main-btn m-10'>View Products</button>
        </div>
    )
}
 
