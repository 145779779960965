import React from 'react'
import { Link } from 'react-router-dom'



export default function Home() {

   return (
      <div className='home '>
            <div className='home-hero  p-5 sm:p-10 flex justify-end'>
               <div className='m-10 w-5/6 sm:w-3/4 md:w-2/4 p-7  bg-white rounded shadow-xl'>
               <h2 className='text-3xl mb-5 font-sarif capitalize'>Welcome to Gomesia Biotech</h2>
               <p className='text-xl md:text-2xl mb-5 text-justify'>We are one of the leading pharmaceutical companies in the world. We offer quality products that address the hormonal issues that people face. We offer affordable prices and timely delivery for our clients from different countries. Our products are certified by the World Health Organization and are trusted by people.</p>
               <Link to='/productAuthentication' className='block text-center m-auto main-btn rounded'>Check Products</Link>
            </div>
            </div>

         <div className='flex flex-col items-center pt-2'> 
         <div className='pb-8 flex flex-col items-center '>
            <h1 className='text-4xl capitalize m-5'>Our products</h1>
            <h5 className='text-xl p-5 md:p-2'>We have a range of 18 products designed to solve various hormonal problems.</h5>
               
         <div className='product-section max-md:flex-col flex justify-center mt-5 mb-5'>
            <div className='w-80 m-5'>
               <img src="/images/product_images/winstrol-100.jpeg" alt="Stanazolole" />
               <h3 className='text-center m-5 text-2xl'>Winstrol-100</h3>
            </div>
            <div className='w-80 m-5'><img src="/images/product_images/androl.jpeg" alt="Androl" />
               <h3 className='text-center m-5 text-2xl'>Androl</h3>
            </div>
            <div className='w-80 m-5'><img src="/images/product_images/d-bol.jpeg" alt="Danabol" />
               <h3 className='text-center m-5 text-2xl'>D-bol</h3>
            </div>
         </div>
            <button className='main-btn rounded'>Learn more</button>
         </div>
         
         <div className='first-block m-10 w-5/6 sm:w-3/4 md:w-2/4 p-10 bg-white rounded shadow-xl'>
               <h2 className='text-3xl mb-5 font-sarif'>О нас</h2>
               <p className='text-2xl mb-5 text-justify'>We are an experienced team of professionals based in Russia. We have been dealing with hormonal preparations for 15 years. Our products are trusted by the WORLD HEALTH ORGANIZATION and used by people in all countries. That's why we are one of the most trusted pharmaceutical companies in the world. You can easily access medicines through our various platforms and solve your problems. We have a full range of medicines for various hormonal problems.<br/>
               <br/>

             We not only work to meet the needs of people in all countries, but also have won the trust of people who previously considered their problems incurable. Our mission is to reach as many people as possible and contribute to a healthy and happy future of the world.
             </p>
               <button className='block m-auto main-btn rounded'>Learn more</button>
         </div>
         
         <div className="container flex justify-center">
         <button className='main-btn rounded m-10'>View products</button>
         </div>
      </div>
      </div>
   )
}
