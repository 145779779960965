import { Route, Routes } from "react-router-dom"
import Layout from './components/Layout';
import About from "./components/child-comp/about"
import Home from "./components/child-comp/home"
import Products from "./components/child-comp/product"
import ErrorPage from "./components/error";
import ProductAuthentication from "./components/child-comp/productAuthentication";

function App() {
    return (
     
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route index element={<Home/>}/>
                <Route path="products" element={<Products/>} />
                <Route path="about" element={<About/>}/>
                <Route path="productAuthentication" element={<ProductAuthentication/>}/>
          </Route>
          <Route path="*" element={<ErrorPage/>}/>
        </Routes>  
    
    );
}

export default App;