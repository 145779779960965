import React, { useState } from 'react';
import allProducts from "../../store/productsList.json"

export default function Products() {
    const [filteredProducts, setFilteredProducts] = useState(allProducts);

    // Filter function to update the products list
    const filterProducts = (type) => {
        if (type === "All") {
            setFilteredProducts(allProducts); // Show all products
        } else {
            setFilteredProducts(allProducts.filter(product => product.type === type)); // Filter by type
        }
    };

    return (
        <div className='flex flex-col custom-min-h'>
            <div className=''>
                <div className='text-center product-main max-sm:flex-col'>
                    <button className='main-btn m-5 rounded' onClick={() => filterProducts("All")}>All Products</button>
                    <button className='main-btn m-5 rounded' onClick={() => filterProducts("Injection")}>Injections</button>
                    <button className='main-btn m-5 rounded' onClick={() => filterProducts("Tablet")}>Tablets</button>
                    <button className='main-btn m-5 rounded' onClick={() => filterProducts("Sarms")}>Sarms</button>
                </div>

                {/* Display filtered products */}
                <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-10 pb-10'>
                    {filteredProducts.map((product, index) => (
                        <div className='text' key={index}>
                            <img className='rounded bg-white px-2 py-2' src={product.path} alt={product.name} />
                            <p className='mt-2'>{product.name}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

