import React from 'react';
import { NavLink } from 'react-router-dom';

const Header = ()=>{
   return(
    <>
     <header className="bg-gray-200 p-2 sticky top-0">
    <div className="flex items-center justify-between flex-wrap">
      <div className="flex">
        <NavLink to="/"><img className=' ml-5 mr-5 h-20 cursor-pointer' src="/logo.png" alt="logo" /> </NavLink>
        <NavLink className='mt-6' to="/"><span className="font-semibold text-2xl tracking-tight text-blue-800">GOMESIA BIOTECH</span></NavLink>
      </div>
      <nav className="block ml-10 md:mr-5 ">
        <NavLink className="inline-block text-xl text-gray-800 hover:text-blue-500 mr-4" to="/">Home</NavLink>
        <NavLink className="inline-block text-xl text-gray-800 hover:text-blue-500 mr-4" to="/about">About</NavLink>
        <NavLink className="inline-block text-xl text-gray-800 hover:text-blue-500 mr-4" to="/products">Products</NavLink>
        <NavLink className="inline-block text-xl text-gray-800 hover:text-blue-500 mr-4" to="/productAuthentication">Product Authentication</NavLink>
      </nav>
    </div>
  </header>   
    </>
   )
}

export default Header;

