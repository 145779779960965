import React,{useRef,useState} from 'react'
import { Link } from 'react-router-dom'

export default function ProductAuthentication() {
        let inputRef = useRef()
        let [flag,setFlag] = useState(0)
        function submitbtn(e)
        {
          e.preventDefault()
          setFlag(false)
          let id = "gl9855312"
          if((inputRef.current.value && inputRef.current.value.trim()=="" ) || inputRef.current.value=="")
          {
            setFlag(0)
            return false;
          }
          
          if(inputRef.current.value.toUpperCase().trim()==id.toUpperCase())
          {
            setFlag(1)
            return true;
          }
          else
          {
            setFlag(2)
          }
            
             return false;
       
        }

    return (
        <div className=' flex flex-col p-1 custom-min-h'>
          <div className='h-44 m-5 flex justify-center mt-5'><img src="/logo.svg" alt="logo" /></div>
          <div className='flex justify-center'>
            <div className='block ml-14 sm:ml-0'>
            <h1 className='text-3xl mb-5'>Enter the product ID</h1>
            <form className="" onSubmit={submitbtn}>
                <input ref={inputRef} className='h-10 pl-2 w-64 rounded' type="text" placeholder='Enter product id' />
                <button  className=' ml-12 sm:ml-5 text-xl text-white bg-[#314a9e] w-32 h-10 rounded m-2 hover:bg-[#0b2783]'>Verify</button>
            </form>
                   <div>{flag==1 ? <h4  className='pl-6 font-bold pt-5 text-[20px] text-[#008000] tracking-wide'>&#10003; Product is Verified</h4>:flag==2 && <h4  className='pl-6 font-bold pt-5 text-[20px] text-red-600 tracking-wide'> &#x2717; Product is not Verified</h4> 
          
       }</div>
            </div>
          </div>
   
        </div>
    )
}
